import { useWindowSize } from '@vueuse/core'
import { computed, ComputedRef } from 'vue'

type UseMobile = {
    isMobile: ComputedRef<boolean>
}

const MOBILE_BREAKPOINT = 992

export function useMobile (): UseMobile {
    const { width } = useWindowSize()
    const isMobile = computed(() => width.value < MOBILE_BREAKPOINT)

    return {
        isMobile
    }
}